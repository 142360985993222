<template>
  <div class="search">
    <van-sticky>
      <van-search
        v-model="content"
        show-action
        placeholder="请输入搜索关键词"
        @search="onSearch"
      >
        <template #left>
          <van-icon
            name="arrow-left"
            color="#1989fa"
            size="18"
            @click.stop="$router.go(-1)"
          />
        </template>
        <template #action>
          <!-- 点击反馈待处理 -->
          <div @click="onSearch" style="color: #fafafa">搜索</div>
        </template>
      </van-search>
    </van-sticky>
    <van-empty image="search" :description="description" v-if="emptyShow" />

    <Card
      :list="list"
      :isListLoading="isListLoading"
      :hasMoreData="hasMoreData"
      @load="load"
      @cardClick="cardClick"
    ></Card>
  </div>
</template>

<script>
import Card from "components/Card.vue";

import { searchListApi } from "request/video";
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Search",
  data() {
    return {
      content: "",
      contentKey: "",
      list: [],
      page: 1,
      isListLoading: true, //列表加载状态
      hasMoreData: true, //是否加载更多
      emptyShow: true, //占位图状态
      description: "暂无搜索内容",
    };
  },

  components: { Card },

  computed: {},

  mounted() {
    this.hasMoreData = false;
    console.log(document.documentElement.scrollTop, document.body.scrollTop);
  },

  activated() {
    console.log("activated");
  },

  deactivated() {
    console.log("deactivated");
  },

  methods: {
    async onSearch() {
      // console.log();
      if (this.content.trim() === "") {
        return this.$toast({
          message: "搜索内容不能为空",
          position: "top",
        });
      }
      this.contentKey = this.content;
      this.page = 1; //缓存重置页码
      this.hasMoreData = true;
      const data = await searchListApi({
        page: this.page,
        content: this.contentKey,
      });
      if (data.code === 401) {
        return this.$toast({
          message: "请登录后在搜索哦～",
          position: "bottom",
        });
      }
      if (data.code) {
        //没有搜索结果
        if (data.list.length <= 0) {
          this.list = [];
          this.emptyShow = true;
          this.description = `找不到您查询 “${this.content}” 相符的视频`;
          return;
        }
        this.list = data.list;
        this.emptyShow = false;
      } else {
        return this.$toast({
          message: data.msg,
          position: "top",
        });
      }
    },
    cardClick(item, index, event) {
      this.$router.push(`/videoplay/${item.id}`);
    },
    load() {
      this.isListLoading = false;
      this.page += 1;
      searchListApi({
        page: this.page,
        content: this.contentKey,
      }).then((data) => {
        data.list.forEach((item, index) => {
          this.list.push(item);
        });

        this.isListLoading = true; //列表加载成功

        if (data.list.length <= 0) {
          this.hasMoreData = false; //数据加载完毕
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .van-search {
  background-color: #131a1e;
}
::v-deep .van-search__content {
  background-color: #212d33;
  margin-left: 0.5rem;
}
::v-deep .van-field__left-icon {
  color: #979797;
}
::v-deep .van-field__control {
  color: #fff;
}
// ::v-deep .van-search__action {
//   background-color:transparent!important;
// }
</style>
