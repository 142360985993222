import Vue from "vue";
import VueRouter from "vue-router";

import store from "@/store";

import Home from "views/Home.vue"; //首页
import My from "views/My.vue"; //我的
import Anecdotes from "views/Anecdotes.vue"; //趣闻
import Persevere from "views/Persevere.vue"; //厂牌
import Vip from "views/Vip.vue"; //开通VIP
import Search from "views/Search.vue"; //搜索
import LikeList from "views/LikeList.vue"; //我的点赞列表
import HistoryList from "views/HistoryList.vue"; //我的历史列表
import LoginRegister from "views/LoginRegister.vue"; //登录注册
import DomesticAutodyne from "views/DomesticAutodyne.vue"; //国产自拍
import DomestiCanchor from "views/DomestiCanchor.vue"; //国产主播
import Ethical from "views/Ethical.vue"; //伦理视频
import VideoPlay from "views/VideoPlay.vue"; //视频播放页

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      title: "首页",
      level: 1,
      keepAlive: true, // 需要缓存
    },
  },
  {
    path: "/domesticautodyne",
    name: "DomesticAutodyne",
    component: DomesticAutodyne,
    meta: {
      title: "国产自拍",
      level: 2,
      keepAlive: true, // 需要缓存
    },
  },
  {
    path: "/domesticanchor",
    name: "DomestiCanchor",
    component: DomestiCanchor,
    meta: {
      title: "国产主播",
      level: 2,
      keepAlive: true, // 需要缓存
    },
  },
  {
    path: "/ethical",
    name: "Ethical",
    component: Ethical,
    meta: {
      title: "伦理视频",
      level: 2,
      keepAlive: true, // 需要缓存
    },
  },
  {
    path: "/videoplay/:id",
    name: "videoplay",
    component: VideoPlay,
    meta: {
      title: "视频播放页",
      level: 3,
      keepAlive: false, // 需要缓存
    },
  },
  {
    path: "/my",
    name: "my",
    component: My,
    meta: {
      title: "我的",
      level: 1,
      keepAlive: false, // 需要缓存
    },
  },
  {
    path: "/likelist",
    name: "Likelist",
    component: LikeList,
    meta: {
      title: "我的点赞",
      level: 2,
      keepAlive: true, // 需要缓存
    },
  },
  {
    path: "/historylist",
    name: "Historylist",
    component: HistoryList,
    meta: {
      title: "浏览历史",
      level: 2,
      keepAlive: true, // 需要缓存
    },
  },
  {
    path: "/loginregister",
    name: "loginregister",
    component: LoginRegister,
    meta: {
      title: "登录注册",
      keepAlive: false, // 需要缓存
    },
  },
  {
    path: "/vip",
    name: "vip",
    component: Vip,
    meta: {
      title: "开通VIP",
      keepAlive: false, // 需要缓存
    },
  },
  {
    path: "/anecdotes",
    name: "anecdotes",

    component: Anecdotes,
    meta: {
      title: "趣闻",
      level: 1,
      keepAlive: false, // 需要缓存
    },
  },
  {
    path: "/persevere",
    name: "persevere",
    component: Persevere,
    meta: {
      title: "厂牌",
      level: 1,
      keepAlive: false, // 需要缓存
    },
  },
  {
    path: "/search",
    name: "Search",
    component: Search,
    meta: {
      title: "搜索",
      level: 2,
      keepAlive: true, // 需要缓存
    },
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

router.beforeEach((to, from, next) => {
  // console.log({ to }, { from });
  //没有缓存的页面浏览器滚动条到顶部
  // if (!to.meta.keepAlive) {
  //   console.log('top');
  //   document.body.scrollTop = 0;
  //   document.documentElement.scrollTop = 0;
  // }

  if (
    (to.meta.level > from.meta.level && to.meta.keepAlive) ||
    (to.meta.level == 1 && to.meta.keepAlive)
  ) {
    store.commit("keepAdd", to.name); //添加缓存
  }
  if (to.meta.level < from.meta.level) {
    store.commit("keepDel", from.name); //删除缓存
  }
  next();
});
export default router;
