import Vue from "vue";
import App from "./App.vue";
import router from "./router";

import Vant from "vant";
import "vant/lib/index.css";
import VueLazyload from "vue-lazyload";
import store from './store'

Vue.config.productionTip = false;

const loadimage = require("assets/video-loading.gif");

Vue.use(Vant).use(VueLazyload, {
  preLoad: 1.3,
  error: loadimage,
  loading: loadimage,
});

new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount("#app");
