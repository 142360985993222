import { request } from "./index";

const token = "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6MiwiYXVkIjoiIiwiZXhwIjoxNjUwODE3NzQ0LCJpYXQiOjE2NTA4MTc2ODQsImlzcyI6IiIsImp0aSI6IjgxZWJhYmMyNGUwOWEzYzNmOGU4MmIxNGU1MzkzZjgwIiwibmJmIjoxNjUwODE3Njg0LCJzdWIiOiIifQ.54NvBOwkJ8gJDJArN69FYPdlajNbqI9VyHUtIQ1SwGk";

//写入视频浏览历史接口
export const userVideoHistoryApi = async (param = {}) => {
  return await request({
    url: '/api/user/userVideoHistory',
    method: "POST", 
    data: param,
    headers: {
      Authorization: localStorage.getItem("token")
        ? localStorage.getItem("token")
        : token,
    },
  });
}

//视频详情接口
export const videoDetailsApi = async (param = {}) => {
  return await request({
    url: '/api/index/videoDetails',
    method: "POST", 
    data: param,
  });
}

//视频详情猜你喜欢接口
export const videoDetailsLikeApi = async (param = {}) => {
  return await request({
    url: '/api/index/videoDetailsLike',
    method: "POST", 
    data: param,
  });
}

//视频详情点赞收藏状态接口
export const videoDetailsStatusApi = async (param = {}) => {
  return await request({
    url: '/api/index/videostatus',
    method: "POST", 
    data: param,
    headers: {
      Authorization: localStorage.getItem("token")
        ? localStorage.getItem("token")
        : token,
    },
  });
}

//视频搜索列表接口
export const searchListApi = async (param = {}) => {
  return await request({
    url: '/api/search/list',
    method: "POST", 
    data: param,
    headers: {
      Authorization: localStorage.getItem("token")
        ? localStorage.getItem("token")
        : token,
    },
  });
}