import { request } from "./index";
//生成过期token跳转登录页，可能会重复。优化方案：把token加入黑名单
const token = "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6MiwiYXVkIjoiIiwiZXhwIjoxNjUwODE3NzQ0LCJpYXQiOjE2NTA4MTc2ODQsImlzcyI6IiIsImp0aSI6IjgxZWJhYmMyNGUwOWEzYzNmOGU4MmIxNGU1MzkzZjgwIiwibmJmIjoxNjUwODE3Njg0LCJzdWIiOiIifQ.54NvBOwkJ8gJDJArN69FYPdlajNbqI9VyHUtIQ1SwGk";
//用户登录注册接口
export const loginRegisterApi = async (param = {}) => {
  return await request({
    url: "/api/loginregister",
    method: "POST",
    data: param,
  });
};

//我的接口
export const MyApi = async (param = {}) => {
  return await request({
    url: "/api/user/my",
    method: "POST",
    data: param,
    headers: {
      Authorization: localStorage.getItem("token")
        ? localStorage.getItem("token")
        : token,
        'Content-Type':'application/x-www-form-urlencoded'
    },
  });
};

//用户视频点赞接口
export const LikeApi = async (param = {}) => {
  return await request({
    url: "/api/user/like",
    method: "POST",
    data: param,
    headers: {
      Authorization: localStorage.getItem("token")
        ? localStorage.getItem("token")
        : token,
    },
  });
};

//用户视频点赞列表接口
export const LikeListApi = async (param = {}) => {
  return await request({
    url: "/api/user/likelist",
    method: "POST",
    data: param,
    headers: {
      Authorization: localStorage.getItem("token")
        ? localStorage.getItem("token")
        : token,
    },
  });
};

//用户视频浏览历史列表接口
export const HistoryListApi = async (param = {}) => {
  return await request({
    url: "/api/user/userVideoHistoryList",
    method: "POST",
    data: param,
    headers: {
      Authorization: localStorage.getItem("token")
        ? localStorage.getItem("token")
        : token,
    },
  });
};