<template> 
  <div>
    <van-empty description="维护中" />
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },

  components: {},

  computed: {},

  mounted() {},

  methods: {},
};
</script>
<style lang="scss" scoped>

::v-deep .van-empty {
  height: 90vh;
  margin: auto 0;
}
</style>
