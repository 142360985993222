import axios from "axios";
import { Toast } from "vant";

export function request(config = {}) {
  if (typeof config !== "object") throw "request() 参数必须是对象";

  //创建axios实例
  const instance = axios.create({
    baseURL: "https://admin.pipio.ml", //默认请求域名
    timeout: 5000, //默认超时时间
  });
  // 添加请求拦截器
  instance.interceptors.request.use(
    (config) => {
      // 在发送请求之前做些什么
      // config.headers.Authorization = localStorage.getItem("token")
      //   ? localStorage.getItem("token")
      //   : 32131321;
      return config;
    },
    (err) => {
      // 对请求错误做些什么
      return Promise.reject(err);
    }
  );

  // 添加响应拦截器
  instance.interceptors.response.use(
    (response) => {
      // 对响应数据做点什么
      //验证token是否存在
      // console.log(response);

      return response.data;
    },
    (err) => {
      // console.log(err.response);
      if (err.response.data.code === 0) {
        Toast({
          message: err.response.data.message ?? "未知错误",
          position: "top",
        });
      }
      return Promise.reject(err);
    }
  );

  return instance(config); //返回Promise
}
