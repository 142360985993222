<template>
  <div class="home">
    <van-sticky>
      <div style="display: flex; background-color: #131a1e">
        <div class="tab" style="width: 85%">
          <van-tabs
            v-model="activeName"
            animated
            background="#131a1e"
            color="#05E5BF"
            title-inactive-color="#ACAFB0"
            title-active-color="#fafafa"
          >
            <van-tab
              :key="index"
              v-for="(item, index) in tabList"
              :title="item.title"
              :name="item.name"
              :to="item.route"
            >
            </van-tab>
          </van-tabs>
        </div>
        <div class="search" style="margin: auto">
          <van-icon
            name="search"
            color="#fafafa"
            size="25"
            @click.stop="$router.push('/search')"
          />
        </div>
      </div>
    </van-sticky>
    <!-- 首页主内容区域 -->
    <div class="main" v-if="$route.path === '/'">
      <van-swipe :loop="false" :width="300">
        <van-swipe-item v-for="(image, index) in images" :key="index">
          <van-image
            width="100%"
            height="30vh"
            :src="image"
            fit="cover"
            style="padding: 0 10px"
          />
        </van-swipe-item>
      </van-swipe>
      <h2 style="color: #fafafa; font-size: 20px">推荐视频</h2>

      <div class="conten">
        <Card
          :list="list"
          :isListLoading="isListLoading"
          :hasMoreData="hasMoreData"
          @load="load"
          @cardClick="cardClick"
        ></Card>
      </div>
    </div>
  </div>
</template>

<script>
import Card from "components/Card.vue";

import { homeVideoListApi } from "request/home";
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Home",
  data() {
    return {
      page: 1,
      isListLoading: true, //列表加载状态
      hasMoreData: true, //是否加载更多
      activeName: 'a',
      tabList: [
        { title: "首页", route: "/", name: 'a' },
        { title: "国产", route: "/domesticautodyne"},
        { title: "主播", route: "/domesticanchor" },
        { title: "伦理", route: "/ethical" },
        // { title: "专辑", route: "/ds" },
        // { title: "专辑", route: "/ds" },
      ],
      images: [
        "https://uploadfile.bizhizu.cn/up/63/fe/78/63fe78e49b59451e65d916d732470b4d.jpg",
        "https://img.syt5.com/2020/0629/20200629062316875.jpg.1680.0.jpg",
        "https://img.syt5.com/2020/0629/20200629062315103.jpg.1680.0.jpg",
      ],
      list: [],
    };
  },

  components: { Card },

  computed: {},

  activated() {
    console.log('activated');
    this.activeName = 'a';
  },

  deactivated() {
    console.log('deactivated');
    this.activeName = 'a';
  },

  async mounted() {
    this.activeName = 'a';
    const videoList = await homeVideoListApi();
    this.list = videoList;
  },

  methods: {
    cardClick(item, index, event) {
      this.$router.push(`/videoplay/${item.id}`);
    },
    load() {
      this.isListLoading = false;
      this.page += 1;
      homeVideoListApi({ page: this.page }).then((data) => {
        // eslint-disable-next-line no-unused-vars
        data.forEach((item, index) => {
          this.list.push(item);
        });

        this.isListLoading = true; //列表加载成功

        if (data.length <= 0) {
          this.hasMoreData = false; //数据加载完毕
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.home {
  .main {
    margin: 20px 10px;

    .conten {
      margin-top: 1rem;
    }
  }
}

::v-deep .van-tab__text {
  font-size: 20px;
}
::v-deep .van-image__img {
  border-radius: 10px;
}
</style>
