import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    keepPages: [],
  },
  getters: {},
  mutations: {
    changeKeepPages(state, status) {
      state.keepPages = status;
    },
    //添加缓存
    keepAdd(state, status) {
      //检查重复
      if (state.keepPages.length <= 0) {
        state.keepPages.push(status);
      }

      for (const item of state.keepPages) {
        if (item === status) {
          return;
        }
      }
      state.keepPages.push(status);
    },
    //通过值删除某个缓存
    keepDel(state, status) {
      state.keepPages.forEach((item, index) => {
        if (item === status) {
          state.keepPages.splice(index, 1);
        }
      });
    },
  },
  actions: {},
  modules: {},
});
