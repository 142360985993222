<template>
  <div class="my">
    <van-notice-bar
      left-icon="volume-o"
      text="网站永久网址: yydssp.vip"
      background="transparent"
      color="rgb(248, 44, 86)"
    />

    <!-- 我的信息卡片 -->
    <div class="info-card">
      <div class="item">
        <div>
          <van-image
            round
            width="4.5rem"
            height="4.5rem"
            :src="item.headimg"
            style="border: 2px solid #b1d5c8"
          />
        </div>
      </div>
      <div class="item">
        <div>
          <p class="nickname" style="font-size: 20px">{{ item.nickname }}</p>
          <p
            class="number"
            style="font-size: 14px; margin-top: 5px; color: #acafb0"
          >
            ID: {{ item.number }}
          </p>
        </div>
      </div>
    </div>

    <!-- 余额、VIP信息 -->
    <!-- <div class="money-card">
      <div class="item">
        <div>
          <p style="font-size: 14px">余额</p>
          <p style="font-size: 26px; color: #01d0c5">
            {{ item.money }} <font>充值</font>
          </p>
        </div>
      </div>
      <div class="item">
        <div @click.stop="$router.push('/vip')">
          <p style="font-size: 14px">VIP剩余天数</p>
          <p style="font-size: 26px; color: #f82c56">
            {{ item.vip_time }} <font>续费</font>
          </p>
        </div>
      </div>
    </div> -->
    <div class="money-card">
      <div class="item">
        <div>
          <p style="font-size: 14px">关注</p>
          <p style="font-size: 26px; color: #01d0c5">0</p>
        </div>
      </div>
      <div class="item">
        <div>
          <p style="font-size: 14px">粉丝</p>
          <p style="font-size: 26px; color: #f82c56">0</p>
        </div>
      </div>
    </div>

    <!-- 事件区域 -->
    <div class="events">
      <van-grid>
        <van-grid-item @click.stop="$router.push('/likelist')">
          <van-icon name="good-job" size="30" />
          <p style="margin: 5px 0">点赞</p>
        </van-grid-item>
        <!-- <van-grid-item @click.stop="">
          <van-icon name="star" size="30" />
          <p style="margin: 5px 0">收藏</p>
        </van-grid-item> -->
        <van-grid-item @click.stop="$router.push('/historylist')">
          <van-icon name="eye" size="30" />
          <p style="margin: 5px 0">历史</p>
        </van-grid-item>
        <!-- <van-grid-item>
          <van-icon name="gold-coin" size="30" />
          <p style="margin: 5px 0">已购</p>
        </van-grid-item> -->
        <!-- <van-grid-item>
          <van-icon name="bag" size="30" />
          <p style="margin: 5px 0">订单</p>
        </van-grid-item> -->
        <!-- <van-grid-item icon="photo-o" text="文字" /> -->
      </van-grid>
    </div>

    <!-- 广告位 -->
    <!-- <van-image
      width="100%"
      height="100"
      src="https://picsum.photos/id/5/5245/3497"
      fit="cover"
      style="border-radius: 15px; padding: 0 5px"
    /> -->

    <!-- 列表 -->
    <!-- <van-cell title="账号管理" is-link :clickable="false" to="" /> -->
    <!-- <van-cell title="推广赚钱" is-link :clickable="false" to="" /> -->
    <van-cell
      title="退出登录"
      is-link
      :clickable="false"
      @click.stop="logout"
    />
    <!-- <van-cell title="关于我们" is-link :clickable="false" to="" /> -->
    <!-- <van-cell title="联系客服" is-link :clickable="false" to="" /> -->

    <van-divider
      :style="{ color: '#e18a3b', borderColor: '#1989fa', padding: '0 16px' }"
    >
      我们致力于研究网络中最尖端的复习资料，现已功课各种网红资源难题，备受全国爱好学习的同学们爱戴，请自觉遵守课堂纪律，做一个爱分享爱学习的人
    </van-divider>
  </div>
</template>

<script>
import { MyApi } from "request/user";

export default {
  data() {
    return {
      item: {},
    };
  },

  components: {},

  computed: {},

  async mounted() {
    const data = await MyApi();
    if (data.code === 401) return this.$router.push("/loginregister");
    if (data.code === 1) this.item = data.data;
  },

  methods: {
    logout() {
      //退出登录
      localStorage.removeItem("token");
      this.$router.push("/loginregister");
    },
  },
};
</script>
<style lang="scss" scoped>
@import "assets/scss/common.scss";
.my {
  color: $mainTitle-color;
  .info-card {
    width: 100%;
    display: flex;
    .item {
      margin: auto 0;
      padding: 5px 5px;
      div {
        width: 100%;
        // height: 100px;
        // background-color: red;
        .nickname,
        .number {
          margin: 0;
        }
      }
    }
  }
  .money-card {
    margin-top: 1rem;
    display: flex;
    .item {
      width: 50%;
      padding: 0 5px;
      div {
        border-radius: 10px;
        width: 100%;
        height: 100px;
        background-color: #1b2326;
        p {
          margin: 0;
          padding: 0.5rem 1rem;
          font {
            font-size: 14px;
            color: #fedc5e;
          }
        }
      }
    }
  }
  .events {
    p {
      color: $subtitle-color;
    }
  }
}
::v-deep .van-grid-item__content {
  background-color: transparent;
}
::v-deep [class*="van-hairline"]::after {
  border: none;
}
::v-deep .van-image__img {
  border-radius: 10px;
}
::v-deep .van-cell {
  color: $mainTitle-color;
  background-color: transparent;
}
::v-deep .van-cell::after {
  border-bottom: none;
}
</style>
