import { request } from "./index";

//vip商品列表接口
export const goodsListApi = async (param = {}) => {
  return await request({
    url: '/api/goods/list',
    method: "POST", 
    data: param,
  });
}
