<template>
  <div class="like-list">
    <van-sticky>
      <van-nav-bar
        title="点赞"
        left-arrow
        :border="false"
        @click-left="$router.go(-1)"
      />
    </van-sticky>
    <Card
      :list="list"
      :isListLoading="isListLoading"
      :hasMoreData="hasMoreData"
      @load="load"
      @cardClick="cardClick"
    ></Card>
  </div>
</template>

<script>
import Card from "components/Card.vue";

import { LikeListApi } from "request/user";

export default {
  name: 'Likelist',
  data() {
    return {
      list: [],
      page: 0,
      isListLoading: true, //列表加载状态
      hasMoreData: true, //是否加载更多
    };
  },

  components: { Card },

  computed: {},

  async mounted() {
    this.load();
  },

  methods: {
    cardClick(item, index, event) {
      this.$router.push(`/videoplay/${item.id}`);
    },
    load() {
      this.isListLoading = false;
      this.page += 1;
      LikeListApi({
        page: this.page,
      }).then((data) => {
        // eslint-disable-next-line no-unused-vars

        data.list.forEach((item, index) => {
          this.list.push(item);
        });

        this.isListLoading = true; //列表加载成功

        if (data.list.length <= 0 || !data.code) {
          this.hasMoreData = false; //数据加载完毕
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "assets/scss/common.scss";

::v-deep .van-nav-bar {
  background-color: $level1-color;
}
::v-deep .van-nav-bar__title,
.van-icon {
  color: $mainTitle-color;
}
</style>
