<template>
  <div class="anecdotes">
    <van-empty description="待开放" />
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },

  components: {},

  computed: {},

  mounted() {},

  methods: {},
};
</script>

<style lang="scss" scoped>
.anecdotes {

}
::v-deep .van-empty {
  height: 90vh;
  margin: auto 0;
}
</style>
