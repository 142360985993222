<template>
  <div class="video-play">
    <van-sticky>
      <div class="top">
        <div
          id="dplayer"
          ref="player"
          style="height: 30vh"
          v-if="dpStatus"
        ></div>
        <!-- 提示充值 -->
        <div class="prompt" style="height: 30vh" v-else>
          <p class="p">
            开通<font style="color: red">VIP</font>会员可享受以下权限
          </p>
          <p class="p">免广告，极速秒播</p>
          <p class="p">VIP视频无限观看</p>
          <div style="margin-top: 1rem; display: flex">
            <van-button
              style="margin: 0 auto; display: block"
              round
              size="small"
              color="linear-gradient(to right, #ff6034, #ee0a24)"
              @click.stop="pop"
            >
              开通VIP
            </van-button>
            <van-button
              style="margin: 0 auto; display: block"
              round
              size="small"
              color="linear-gradient(135deg,#17ead9,#6078ea)"
              @click.stop="pop"
            >
              买断本片
            </van-button>
          </div>
        </div>
        <van-icon
          name="arrow-left"
          class="arrow"
          size="25"
          @click.stop="$router.go(-1)"
        />
      </div>
    </van-sticky>
    <!-- 功能区域 -->
    <div class="ribbon">
      <!-- 标题 -->
      <h3>{{ item.title }}</h3>
      <!-- 其他信息 -->
      <ul class="info">
        <li>{{ item.hits }}次播放</li>
        <li>{{ item.video_time }}</li>
      </ul>
      <!-- 事件区域 -->
      <div class="events">
        <van-grid>
          <van-grid-item @click.stop="goodJob">
            <van-icon name="good-job" size="30" :color="goodJobComputed" />
            <p style="margin: 5px 0" :style="{ color: goodJobComputed }">
              点赞
            </p>
          </van-grid-item>
          <!-- <van-grid-item @click.stop="star">
            <van-icon name="star" size="30" :color="starComputed" />
            <p style="margin: 5px 0" :style="{ color: starComputed }">收藏</p>
          </van-grid-item>
          <van-grid-item>
            <van-icon name="share" size="30" />
            <p style="margin: 5px 0">分享</p>
          </van-grid-item> -->
          <!-- <van-grid-item icon="photo-o" text="文字" /> -->
        </van-grid>
      </div>
      <!-- 标签 -->
      <div class="tag">
        <van-tag
          :key="i"
          v-for="(lab, i) in item.labs"
          type="primary"
          size="large"
          color="#1b232a"
          text-color="#979797"
          style="margin: 5px 10px 10px 0"
          >{{ lab }}</van-tag
        >
      </div>
      <!-- 广告位 -->
      <!-- <van-image
        width="100%"
        height="100"
        src="https://picsum.photos/id/5/5245/3497"
        fit="cover"
        style="border-radius: 15px"
      /> -->
      <!-- 猜你喜欢 -->
      <div class="relat">
        <h3>猜你喜欢</h3>
        <Card
          :list="list"
          :isListLoading="isListLoading"
          :hasMoreData="hasMoreData"
          @load="load"
          @cardClick="cardClick"
        ></Card>
      </div>
      <!-- 滑动到顶部 git:https://github.com/whevether/vue-backtop -->
      <!-- <BackTop :defaultProps="55" :date="1000" color="#ffffff"></BackTop> -->
    </div>

    <van-overlay :show="show" @click="show = false" :lock-scroll="true">
      <div
        class="wrapper"
        @click.stop
        style="width: 100px; height: 300px; background-color: #fff"
      >
        <div class="block" />
      </div>
    </van-overlay>
  </div>
</template>

<script>
import Hls from "hls.js";
import Dplyaer from "dplayer";
import BackTop from "vue-backtop";

import Card from "components/Card.vue";

import { homeVideoListApi } from "request/home";
import {
  videoDetailsApi,
  videoDetailsLikeApi,
  videoDetailsStatusApi,
  userVideoHistoryApi,
} from "request/video";
import { MyApi, LikeApi } from "request/user";
export default {
  data() {
    return {
      userData: {},
      list: [],
      page: 1,
      isListLoading: true, //列表加载状态
      hasMoreData: true, //是否加载更多
      hls: null,
      dp: null,
      item: [],
      iconColor: "#a1a1a3", //默认颜色
      iconClickColor: "#F82C56", //点赞点击颜色
      starIconClickColor: "#ecd452", //收藏点击颜色
      goodJobStatus: false, //点赞状态
      starStatus: false, //收藏状态
      // isVip: false, //是否是Vip
      dpStatus: true, //播放器状态
      show: false,
    };
  },

  // eslint-disable-next-line vue/no-unused-components
  components: { Card, BackTop },
  beforeRouteUpdate(to, from, next) {
    next();
  },
  computed: {
    goodJobComputed() {
      return this.goodJobStatus ? this.iconClickColor : this.iconColor;
    },
    starComputed() {
      return this.starStatus ? this.starIconClickColor : this.iconColor;
    },
  },

  beforeDestroy() {
    this.hls.destroy();
    this.dp.destroy();
  },

  async mounted() {
    const _this = this;
    //获取影片信息
    this.item = await videoDetailsApi({ id: this.$route.params.id });
    //获取个人信息
    this.userData = await MyApi();
    //获取点赞收藏状态
    const status = await videoDetailsStatusApi({
      video_id: this.$route.params.id,
    });
    if (status.code === 1) {
      this.goodJobStatus = status.data.like;
    }
    //写入用户浏览历史
    userVideoHistoryApi({ video_id: this.$route.params.id });
    this.load();

    this.dp = new Dplyaer({
      container: this.$refs.player,
      lang: "zh-cn",
      video: {
        url: this.item.video_src,
        type: "customHls",
        customType: {
          // eslint-disable-next-line no-unused-vars
          customHls: function (video, player) {
            _this.hls = new Hls();
            _this.hls.loadSource(video.src);
            _this.hls.attachMedia(video);
          },
        },
      },
    });

    //判断是否vip
    // if (!this.userData.data.vip_status) {
    //   this.dp.on("timeupdate", () => {
    //     let currentTime = Math.floor(this.dp.video.currentTime);
    //     if (currentTime > 10) {
    //       this.dpStatus = false;
    //       this.hls.destroy();
    //       this.dp.destroy();
    //       //兼容全屏
    //       document
    //         .querySelector("body")
    //         .classList.remove("dplayer-web-fullscreen-fix");
    //     }
    //     // console.log(currentTime);
    //   });
    // }
  },

  methods: {
    pop() {
      //弹出充值VIP弹窗
      this.show = true;
    },
    cardClick(item, index, event) {
      this.$router.push(`/videoplay/${item.id}`);
    },
    load() {
      this.isListLoading = false;
      this.page += 1;
      videoDetailsLikeApi({
        type: this.item.type,
        title: this.item.title,
        page: this.page,
      }).then((data) => {
        // eslint-disable-next-line no-unused-vars

        data.list.forEach((item, index) => {
          this.list.push(item);
        });

        this.isListLoading = true; //列表加载成功

        if (data.list.length <= 0 || !data.code) {
          this.hasMoreData = false; //数据加载完毕
        }
      });
    },
    async goodJob() {
      //点赞
      this.goodJobStatus = !this.goodJobStatus;
      const data = await LikeApi({
        status: this.goodJobStatus,
        video_id: this.$route.params.id,
      });
      if (data.code === 401) return alert(data.msg);
      console.log(data);
    },
    star() {
      //收藏
      this.starStatus = !this.starStatus;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "assets/scss/common.scss";

.video-play {
  color: $video-play-color;
  .top {
    position: relative;
    .arrow {
      position: absolute;
      top: 10px;
      left: 15px;
      padding: 5px;
      background-color: rgba(0, 0, 0, 0.6);
      border-radius: 50%;
    }
    .prompt {
      padding-top: 1rem;
      background-color: #000000;
      .p {
        margin: 0;
        text-align: center;
        padding-top: 1rem;
        color: #e0e0e0;
        font-size: 14px;
      }
    }
  }

  .ribbon {
    padding: 0 10px;

    h3 {
      font-size: 16px;
      font-weight: 500;
    }

    .info {
      display: flex;
      li {
        color: $video-play-2color;
        font-size: 12px;
        margin-right: 10px;
        font-weight: 400;
      }
    }
    .events {
    }
    .relat {
      width: 100%;
      color: #cfd0d0;
      font-size: 18px;
      margin-right: 10px;
      font-weight: bold;
    }
  }
}
::v-deep .van-grid-item__content {
  background-color: transparent;
}
::v-deep [class*="van-hairline"]::after {
  border: none;
}
::v-deep .van-image__img {
  border-radius: 10px;
}
</style>
