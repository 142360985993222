<template>
  <div class="vip">
    <van-sticky>
      <van-nav-bar
        title="开通VIP"
        left-arrow
        :border="false"
        @click-left="$router.go(-1)"
      />
    </van-sticky>

    <div class="main">
      <div
        class="item"
        :key="index"
        v-for="(item, index) in list"
        @click.stop="active(item, index)"
      >
        <div :class="{ active: index === idx }">
          <p style="color: #fafafa; font-size: 16px">{{ item.title }}</p>
          <p style="font-size: 28px; color: #d34e7d; font-weight: 700">
            ¥{{ item.price }}
          </p>
          <p
            style="
              color: #5e5e5e;
              font-size: 12px;
              text-decoration: line-through;
            "
          >
            原价 ¥{{ item.originalPrice }}
          </p>
        </div>
      </div>
    </div>
    <van-button
      type="primary"
      block
      color="#d34e7d"
      text="#fafafa"
      @click.stop="buy"
      >立即购买</van-button
    >

    <van-divider style="color: #fedc5e"
      >如遇支付问题或充值未到账请联系邮箱 0801mir@gmail.com</van-divider
    >

    <van-share-sheet v-model="showShare" :options="options" @select="onSelect" cancel-text="" />
  </div>
</template>

<script>
const usdc = require("assets/usdc.png");
const aliPay = require("assets/alipay.png");
const wxPay = require("assets/wxpay.png");

import { goodsListApi } from "request/goods";

export default {
  data() {
    return {
      idx: 0,
      item: {},
      list: [
        // { title: "日卡会员", price: 10, originalPrice: 20, status: true },
        // { title: "月卡会员", price: 25, originalPrice: 55, status: false },
        // { title: "季卡会员", price: 45, originalPrice: 75, status: false },
        // { title: "年卡会员", price: 99, originalPrice: 129, status: false },
        // { title: "永久会员", price: 199, originalPrice: 299, status: false },
      ],
      showShare: false,
      options: [
        // {
        //   name: "余额支付",
        //   icon: usdc,
        // },
        {
          name: "支付宝支付",
          icon: aliPay,
        },
        // {
        //   name: "微信支付",
        //   icon: wxPay,
        // },
      ],
    };
  },

  components: {},

  computed: {},

  async mounted() {
    const data = await goodsListApi();
    this.list = data.list;
    //初始化item
    this.item = this.list[this.idx];
  },

  methods: {
    onSelect(option) {
      if (option.name === '余额支付') return this.$toast('支付通道正在维护中');
      if (option.name === '微信支付') return this.$toast('支付通道正在维护中');

      if (option.name === '支付宝支付') {
        // window.open(`http://jiucao.in/api/pay?id=${this.item.id}&uid=${localStorage.getItem("uid")}&type=vip`);
        window.open(`http://admin.pipio.ml/api/pay?id=${this.item.id}&uid=${localStorage.getItem("uid")}&type=vip`);
      }
      this.showShare = false;
    },
    active(item, index) {
      this.idx = index;
      this.item = item;
    },
    buy() {
      //立即购买
      this.showShare = true;
      console.log(this.item);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "assets/scss/common.scss";
.vip {
  .main {
    width: 100%;
    display: flex;
    justify-content: left;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 15px;
    .item {
      width: 50%;
      padding: 10px 5px;
      .active {
        background-color: rgba(211, 78, 125, 0.15);
        border-color: #d34e7d;
      }
      div {
        width: 100%;
        height: 120px;
        border-radius: 5px;
        border: 1px solid #3e3e3e;

        p {
          padding-top: 10px;
          text-align: center;
          margin: 0;
        }
      }
    }
  }
}

::v-deep .van-nav-bar {
  background-color: $level1-color;
}
::v-deep .van-nav-bar__title,
.van-icon {
  color: $mainTitle-color;
}
::v-deep .van-popup {
  background-color: $level1-color;
}
::v-deep .van-share-sheet__name {
  color: #acafb0;
  font-size: 14px;
}
</style>
