import { request } from "./index";

//首页视频列表接口
export const homeVideoListApi = async (param = {}) => {
  return await request({
    url: '/api/index/homeList',
    method: "POST", 
    data: param,
  });
}

//首页国产视频列表接口
export const homeDomesticListApi = async (param = {}) => {
  return await request({
    url: '/api/index/homeDomesticList',
    method: "POST", 
    data: param,
  });
}