<template>
  <div id="app">
    <keep-alive :include="$store.state.keepPages">
      <router-view v-if="$route.meta.keepAlive"></router-view>
      <router-view :key="key" v-else></router-view>
    </keep-alive>
    <div style="padding-top: 4rem">
      <van-tabbar
        route
        :safe-area-inset-bottom="true"
        style="background-color: #1b2326; margin-top: 2rem"
        inactive-color="#64676C"
        active-color="#F82C56"
        :border="false"
        v-model="active"
      >
        <van-tabbar-item
          to="/"
          icon="home-o"
          style="background-color: transparent"
          >首页</van-tabbar-item
        >
        <!-- <van-tabbar-item
          to="/persevere"
          icon="flag-o"
          style="background-color: transparent"
          >厂牌</van-tabbar-item
        >
        <van-tabbar-item
          to="/anecdotes"
          icon="fire-o"
          style="background-color: transparent"
          >趣闻</van-tabbar-item
        > -->
        <van-tabbar-item
          to="/my"
          icon="contact"
          style="background-color: transparent"
          >我的</van-tabbar-item
        >
      </van-tabbar>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      active: 0,
    };
  },

  components: {},

  computed: {
    key() {
      //解决相同页面参数变化组件不刷新问题
      return this.$route.path + Date.now();
    },
    keepPages() {
      return this.$store.getters.getKeepPages;
    },
  },

  mounted() {
  },

  methods: {

  },
};
</script>

<style lang="scss">
@import "assets/scss/common.scss";
body {
  // width: 375px;
  background-color: $level1-color;
}
</style>
