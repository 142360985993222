<template>
  <div class="loginregister">
    <van-nav-bar title="登录" left-arrow :border="false" />

    <van-notice-bar
      left-icon="volume-o"
      text="请牢记您注册的账号密码，这是您找回账号的唯一凭证，有任何问题请及时联系在线客服！"
      background="transparent"
      color="rgb(248, 44, 86)"
    />

    <div class="main">
      <h3>账号</h3>
      <van-field v-model="user" placeholder="请输入账号" />
      <h3>密码</h3>
      <van-field v-model="pass" placeholder="请输入密码" type="password" />
      <h3>确认密码</h3>
      <van-field
        v-model="confirmPass"
        placeholder="请输入确认密码"
        type="password"
      />

      <van-button
        type="primary"
        size="large"
        style="margin-top: 2rem"
        color="rgb(248, 44, 86)"
        @click.stop="loginRegister"
        >登录</van-button
      >

      <van-divider style="color: #fedc5e"
        >未注册的用户填写账号密码完毕自动注册登录，已注册的用户输入账号密码完毕即可自动登录！</van-divider
      >
    </div>
  </div>
</template>

<script>
import { loginRegisterApi } from "request/user";

export default {
  data() {
    return {
      user: "",
      pass: "",
      confirmPass: "",
    };
  },

  components: {},

  computed: {},

  mounted() {},

  methods: {
    async loginRegister() {
      //登录注册
      if (this.user.trim() === "") {
        return this.$toast({
          message: "请输入账号",
          position: "top",
        });
      }
      if (this.pass.trim() === "") {
        return this.$toast({
          message: "请输入密码",
          position: "top",
        });
      }
      if (this.confirmPass.trim() === "") {
        return this.$toast({
          message: "请输入确认密码",
          position: "top",
        });
      }
      if (this.pass != this.confirmPass) {
        return this.$toast({
          message: "两次输入的密码不一致",
          position: "top",
        });
      }

      const data = await loginRegisterApi({user: this.user, pass: this.pass});

      if (data.code === 1) {
        //写入token
        localStorage.setItem('token', data.token);
        localStorage.setItem('uid', data.uid);
        //登录成功跳转逻辑...
        this.$router.go(-1);
        // alert('登录成功')
      }

      if (data.code === 0) {
        return this.$toast({
          message: data.msg,
          position: "top",
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "assets/scss/common.scss";

.loginregister {
  .main {
    padding: 10px;

    h3 {
      color: $subtitle-color;
    }
  }
}

::v-deep .van-nav-bar {
  background-color: transparent;
}
::v-deep .van-nav-bar__title,
.van-icon {
  color: $mainTitle-color;
}
::v-deep .van-cell {
  // color: $mainTitle-color;
  padding: 5px 0px;
  border-bottom: 1px solid $video-play-2color;
  background-color: transparent;
}
::v-deep .van-field__control {
  color: $mainTitle-color;
}
::v-deep .van-cell::after {
  border: none;
}
</style>
