<template>
  <div class="card" ref="card">
    <div class="box">
      <div class="child-box" v-on:click="$emit('cardClick', item, i, $event)" :key="i" v-for="(item, i) in list">
        <div class="main">
          <!-- 图片懒加载 -->
          <img v-lazy="item.url" />
          <!-- <img :src="item.url" @error="imgError($event)" />  -->
        </div>
        <!-- 底部功能区域 -->
        <div class="bottom">
          <!-- 标题 -->
          <span>{{ item.title }}</span>
          <!-- 其他信息 -->
          <div class="other"></div>
        </div>
        <!-- 小标签 -->
        <div class="label" v-if="item.labelStatus">{{ item.labelName }}</div>
      </div>
    </div>
    <div class="loading" v-if="!isListLoading">正在加载中</div>
    <!-- <div class="loading" v-if="!hasMoreData">没有数据了哦</div> -->
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Tabbar",
  props: {
    mainColor: {
      type: String,
      default: "#ACAFB0",
    },
    isListLoading: {
      //列表loading
      type: Boolean,
      default: true,
    },
    hasMoreData: {
      //是否加载更多
      type: Boolean,
      default: true,
    },
    list: Array,
    errorImg: {
      //图片加载失败占位图
      type: String,
      default:
        "https://cdn-abpeb.nitrocdn.com/KplkaogbLnamcJQruoJyTyNEtrDqAJTm/assets/static/optimized/rev-ee87c18/wp-content/plugins/elementor/assets/images/placeholder.png",
    },
  },
  data() {
    return {
      // pageNum: 1, // 页码
      // pageSize: 10, // 每页数量，默认为10
      // isListLoading: this.isListLoading, // 列表loading
      // hasMoreData: true, // 是否加载更多
      // totalCount: 20, // 数据总数量
    };
  },
  mounted() {
    // 添加滚动事件，检测滚动到页面底部
    window.addEventListener("scroll", this.debounce(this.onReachBottom, 300));
  },
  methods: {
    locationCardClick(item, i, event) {
      //绑定点击事件
      this.$emit('click', item, i, event);
    },
    imgError(event) {
      //图片加载失败事件
      event.target.src = this.errorImg;
    },
    getList() {
      //数据正在加载中
      // eslint-disable-next-line vue/no-mutating-props
      // this.isListLoading = false;

      this.$emit("load");
      // setTimeout(() => {
      //   for (let i = 0; i <= 5; i++) {
      //     // eslint-disable-next-line vue/no-mutating-props
      //     this.list.push({
      //       url: "https://s3.bmp.ovh/imgs/2022/04/20/0c97d99f6936b24a.png",
      //     });
      //   }
      //   // eslint-disable-next-line vue/no-mutating-props
      //   this.isListLoading = true;
      // }, 3000);

      // let toalPageSize = Math.ceil(this.totalCount / this.pageSize); //总页码

      // if (toalPageSize <= this.pageNum) {
      //   //数据加载完毕
      //   this.hasMoreData = false;
      // }
    },
    onReachBottom() {
      const scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop; // 页面滚动的高度
      const clientHeight = document.documentElement.clientHeight; // 可视区域高度
      const scrollHeight =
        document.documentElement.scrollHeight || document.body.scrollHeight; // 可滚动内容的高度
      const bottomOfWindow = scrollTop + clientHeight >= scrollHeight - 10; // 是否触底

      if (bottomOfWindow && this.hasMoreData && this.isListLoading) {
        this.getList();
        // this.pageNum += 1; //页面数量+1
        setTimeout(this.scrollToBottom, 0);
      }
    },
    debounce(fn, delay) {
      //防抖函数
      var timer; // 维护一个 timer
      return function () {
        var _this = this; // 取debounce执行作用域的this
        var args = arguments;
        if (timer) {
          clearTimeout(timer);
        }
        timer = setTimeout(function () {
          fn.apply(_this, args); // 用apply指向调用debounce的对象，相当于_this.fn(args);
        }, delay);
      };
    },
    scrollToBottom() {
      //滚动条滚动到底部
      (function smoothscroll() {
        const currentScroll =
          document.documentElement.scrollTop || document.body.scrollTop; // 已经被卷掉的高度
        const clientHeight = document.documentElement.clientHeight; // 浏览器高度
        const scrollHeight = document.documentElement.scrollHeight; // 总高度
        if (scrollHeight - 10 > currentScroll + clientHeight) {
          window.requestAnimationFrame(smoothscroll);
          window.scrollTo(
            0,
            currentScroll + (scrollHeight - currentScroll - clientHeight) / 2
          );
        }
      })();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "assets/scss/common.scss";
.card {
  width: 100%;
  // padding: 0 10px;
  color: $aTitle-color;
  .box {
    width: 100%;
    margin: 0 auto;
    // padding: 5px;
    display: flex;
    display: -webkit-flex;
    justify-content: left;
    align-items: center;
    flex-wrap: wrap;

    .child-box {
      position: relative;
      width: 50%;
      padding: 5px 5px;
      .main {
        width: 100%;
        height: 100%;

        img {
          border-radius: 10px;
          width: 100%;
          height: 100px;
          object-fit: cover;
        }
      }

      .bottom {
        // margin: 5px 0;
        span {
          display: block;
          font-size: 0.855rem;
          overflow: hidden; //超出的文本隐藏
          text-overflow: ellipsis; //溢出用省略号显示
          white-space: nowrap; //溢出不换行
        }
        .other {
        }
      }

      .label {
        color: $mainTitle-color;
        position: absolute;
        top: 15px;
        left: 10px;
        background-color: rgba(0, 0, 0, 0.8);
        border-radius: 25px;
        padding: 0.1rem 0.5rem;
        font-size: 0.355rem;
      }
    }
  }

  .loading {
    width: 100%;
    text-align: center;
    margin: 0.5rem 0;
    color: $subtitle-color;
  }
}
</style>
