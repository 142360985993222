<template>
  <div class="home-found">
    <van-sticky>
      <van-nav-bar
        title="伦理"
        left-arrow
        :border="false"
        @click-left="$router.go(-1)"
      />
    </van-sticky>
    <div class="card" style="padding:.5rem .5rem">
      <Card
        :list="list"
        :isListLoading="isListLoading"
        :hasMoreData="hasMoreData"
        @load="load"
        @cardClick="cardClick"
      ></Card>
    </div>
  </div>
</template>

<script>
import Card from "components/Card.vue";

import { homeDomesticListApi } from "request/home";
export default {
  name: "Ethical",
  data() {
    return {
      list: [],
      page: 0,
      isListLoading: true, //列表加载状态
      hasMoreData: true, //是否加载更多
    };
  },

  components: { Card },

  computed: {},

  async mounted() {
    this.load();
  },

  methods: {
    cardClick(item, index, event) {
      this.$router.push(`/videoplay/${item.id}`);
    },
    load() {
      this.isListLoading = false;
      this.page += 1;
      homeDomesticListApi({
        page: this.page,
        type: '视频伦理'
      }).then((data) => {
        data.forEach((item, index) => {
          this.list.push(item);
        });

        this.isListLoading = true; //列表加载成功

        if (data.length <= 0) {
          this.hasMoreData = false; //数据加载完毕
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "assets/scss/common.scss";
.home-found {
}

::v-deep .van-nav-bar {
  background-color: $level1-color;
}
::v-deep .van-nav-bar__title,
.van-icon {
  color: $mainTitle-color;
}
</style>
